import { graphql } from 'gatsby'
import get from 'lodash/get'
import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import PhotoPostListing from '../components/PhotoPostListing'

class PhotosPostIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(
      this,
      'props.data.site.siteMetadata.description'
    )
    const posts = get(this, 'props.data.allMarkdownRemark.edges').filter(
      p => p.node.frontmatter.template === 'photo'
    )

    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[{ name: 'description', content: siteDescription }]}
          title={siteTitle}
        />
        <h1>Photos</h1>
        <div
          className="photo-index-grid"
          style={{
          }}
        >
          {posts.length > 1 ? posts.map(({ node }) => (
            <PhotoPostListing node={node} key={node.id} />
          )) : <p>There are no photos posted here yet</p>}
        </div>
      </Layout>
    )
  }
}

export default PhotosPostIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          excerpt(format: HTML)
          fields {
            tagSlugs
          }
          frontmatter {
            template
            date(formatString: "MMMM DD, YYYY")
            tags
          }
        }
      }
    }
  }
`
